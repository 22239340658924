<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-default section-text">
      <div class="container m-container-only-s">
        <div v-if="$i18n.locale === 'en'">
          <div class="content">
            <h1 class="title">Help</h1>
            <br /><br />
            <h4>
              Some movies are unavailable to me. Why?
            </h4>
            <article class="answer">
              Users, who have Android-based devices might see the 'content
              unavailable' sign on some devices.
              <br />
              <br />Your device needs to be specially prepared and checked, to
              play some of the movies. It's part of security policy of the
              studios, who provide the content onboard. <br />
              <br />You need to have you device prepared only once and it takes
              just a few seconds. It is important to complete preparation using
              cellular service before boarding on the plane. <br />
              <br />Please visit IFE.ONE and play demo video that starts
              automatically. <br />
              <br />While playing demo video, your device will be set up. Please
              pay attention that demo video has to be played on every device you
              want to use during the flight.
            </article>
            <br /><br />
            <h4>
              The content-service was paid, but not performed. What should I do?
            </h4>
            <article class="answer">
              Please, text about your problem to
              <a href="mailto:ife.support@aerogroup.ru"
                >ife.support@aerogroup.ru</a
              >, amount charged for non-performed services will be returned in
              full.
            </article>
            <br /><br />
            <h4>
              What are the system requirements?
            </h4>
            <br />
            <article class="answer">
              <h4>Apple Devices</h4>
              <span class="answer-subtitle"
                >Supports devices with iOS 12 and later.</span
              >
              <br /><br />
              <h4>Android Devices</h4>
              <span class="answer-subtitle">
                Supports devices with operating system versions:Android 5.0
                (Lollipop) and later. Google Chrome 62.0+ version is required.
                Devices are required to support Widevine L1
              </span>
              <br />
              <br />
              <h4>For any device</h4>
              <span>
                Entertainment program can be played back only on devices with
                the official firmware. The playback is not guaranteed on any
                'hacked' devices (jailbroken or rooted).
              </span>
            </article>
          </div>
        </div>
        <div v-else>
          <div class="content">
            <h1 class="title">Помощь</h1>
            <br /><br />
            <h4>
              Мне доступны не все фильмы. Почему?
            </h4>
            <article class="answer">
              У пользователей устройств с OS Android на некоторых фильмах в
              системе вы можете увидеть сообщение о недоступности контента.
              <br />
              <br />Для проигрывания некоторых фильмов ваше устройство должно
              быть специальным образом проверено и подготовлено. Это часть
              политики безопасности киностудий, предоставляющих контент на борту
              самолета. <br />
              <br />Подготовку устройства нужно пройти один раз, и это занимает
              несколько секунд. Важно сделать это в зоне действия интернета — то
              есть перед взлетом. <br />
              <br />Для этого нужно зайти на сайт IFE.ONE и проиграть
              демо-ролик, который запустится автоматически. <br />
              <br />В процессе проигрывания демо-ролика ваше устройство будет
              активировано. Ролик надо запустить на каждом устройстве, которое
              вы хотите использовать для просмотра фильмов на борту самолета.
            </article>
            <br /><br />
            <h4>
              Платная контент-услуга была оплачена, но не была оказана. Что
              делать?
            </h4>
            <article class="answer">
              Напишите о возникшей проблеме по адресу
              <a href="mailto:ife.support@aerogroup.ru"
                >ife.support@aerogroup.ru</a
              >, списанные средства за неоказанные услуги будут возвращены в
              полном объеме.
            </article>
            <br /><br />
            <h4>
              Какие системные требования для проигрывания контента на борту?
            </h4>
            <br />
            <article class="answer">
              <h4>Для устройств Apple</h4>
              <span class="answer-subtitle"
                >Поддерживаемая версия операционной системы:</span
              >
              <span>iOS 12 и выше.</span>
              <br /><br />
              <h4>Устройства Android</h4>
              <span class="answer-subtitle"
                >Поддерживаемая версия операционной системы:</span
              >
              <span
                >Android 5.0 (Lollipop) и выше. Наличие браузера Google Chrome
                версии 62+Требование к устройствам: Поддержка Widevine L1</span
              >
              <br />
              <br />
              <h4>Для устройств всех производителей</h4>
              <span>
                Развлекательная программа может проигрываться только на
                устройствах с официальной прошивкой. На устройствах с нарушенной
                защитой (jailbroken и rooted) работоспособность системы не
                гарантируется.
              </span>
            </article>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Help",
  title() {
    return this.$t("pages.help");
  }
};
</script>

<style scoped></style>
